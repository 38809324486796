html,
body {
  background-color: #efefef;
  font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  /*font-size: 1rem;*/
  font-weight: 400;
  line-height: 1.5;
  color: #000;
}
body {
  overflow-x: hidden;
  height: auto !important;
}
.page-content {
  min-height: calc(75vh);
  margin-top: 110px;
  padding: 20px 0 20px 0;
}
footer,
.bg-theme {
  background-color: #08192b;
}
footer ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
footer h5 {
  color: #bdbdbd;
}
footer ul {
  padding: 0px;
}
footer ul li {
  padding: 7px 0;
  list-style: none;
  margin: 0px;
}
footer hr {
  color: #fff;
}
a {
  text-decoration: none;
}
.header-top {
  background-color: #081854;
}
.card-border-btm {
  border-bottom: 4px solid #081854;
}

iframe {
  display: none;
}
.page-content iframe {
  display: initial !important;
}
.form-select,
.form-control {
  font-size: 13px;
}
.form-select-sm,
.form-control-sm {
  font-size: 12px;
}
form label {
  margin-bottom: 6px;
}

.context_box_title {
  background-image: linear-gradient(132deg, #081854, #080e7629);
  color: #fff;
  font-size: 12px;
}

.context_box_title h5 {
  font-size: 14px;
  color: #fff;
  padding: 5px 0 0 5px;
}
table.hsc-mark-table thead tr th {
  font-size: 12px;
  text-transform: uppercase;
}
table.hsc-mark-table tbody tr td input,
table.hsc-mark-table tbody tr td select {
  font-size: 12px;
}

.ps__loader {
  position: fixed;
  z-index: 9999;
  width: 100%;
  background-color: #00000040;
  /* margin-top: -58px; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /*
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: calc(120vh);
  background-color: #00000040;
  margin-top: -58px;
  display: flex;
  align-items: center;
  justify-content: center;*/
}
.ps__loader__body {
  background-color: #f8fcff;
  padding: 20px;
  text-align: center;
  border-radius: 15px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 12px;
  /* font-weight: 700; */
  color: white;
  background-color: #08192b;
  display: inline-block;
  padding: 4px 8px;
  margin-top: 5px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #0a3f76;
}
.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.bg-success-light {
  background-color: #d7ffd7 !important;
}
.btn-xs {
  font-size: 11px;
  padding: 1px 5px;
}
.bg-primary-light {
  background-color: #e7f7ff !important;
}

.admissionenquiry {
  height: 30px !important;
}
.groupRadio .form-check-input {
  padding: 10px;
}
.groupRadio .form-check-label {
  padding-left: 5px;
  padding-top: 4px;
}

.selHt {
  height: 30px !important;
}

.nav-tabs [data-rr-ui-event-key="regular"] {
  background-color: #2f9d33 !important;
  font-size: 16px;
  color: #fff !important;
  font-weight: 600;
  padding: 23px;
}
.nav-tabs [data-rr-ui-event-key="self"] {
  background-color: #09069c !important;
  font-size: 16px;
  color: #fff !important;
  font-weight: 600;
  padding: 23px;
}
